body {
    background-color: white;
}

.rt-slide-nav {
    position: fixed;
    background: #fff;
    width: 100%;
    left: 0;
    transform: translateY(100%);
    transition: 1s;
    padding: 30px;
    overflow-y: auto;
    z-index: 999;
}

.rt-show {
    position: fixed;
    background: #fff;
    width: 100%;
    left: 0;
    transform: translateY(0%);
    transition: 1s;
    padding: 30px;
    overflow-y: auto;
    top: 18%;
    z-index: 999;
    -webkit-box-shadow: 0 0 10px 0 #0000001c;
    box-shadow: 0 0 10px 0 #0000001c;
}

.sidebarBtn {
    margin-right: 20px;
    cursor: pointer;
}

@media(max-width:768px) {
    .rt-show {
        top: 20%;
    }

}

@media(max-width:576px) {
    .rt-show {
        top: 10%;
    }

    .sidebarBtn {
        margin-right: 20px;
    }
}

@media(min-width:768px) {
    .rt-show {
        top: 20%;
    }

}

.new-animation {
    animation: fadeIn 1s infinite ease-in-out;
}

.bg-main-img {
    background: url("../public/assets/images/main_logo.png") no-repeat center;
}

.slick-prev {
    left: 26px !important;
    z-index: 999 !important;
}

.slick-prev::before {
    font-size: 44px !important;
}

.slick-next {
    right: 26px !important;
    z-index: 999 !important;
}

.slick-next::before {
    font-size: 44px !important;
}

.css-13cymwt-control {
    border: 1px solid black !important;
}